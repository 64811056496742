<template>
  <div class="y-calendar-container" :class="{ 'button-container': type == 'button' }">
    <input
      type="text"
      class="form-control"
      :class="{ 'hide-opacity': type !== 'input' }"
      id="y-calendar-input"
      :placeholder="$t('generic-str.never')"
      @focus="setDateToToday"
    />
    <span
      class="material-icons-outlined c-pointer icon-calendar p-relative"
      @click="show"
      v-if="type === 'icon'"
      :class="iconClass"
      >calendar_today</span
    >
    <button
      type="button"
      :class="btnClass"
      v-if="type === 'button'"
      @click="show"
      v-tooltip.top="`${this.date}`"
    >
      {{ btnLabel }}
    </button>
  </div>
</template>

<script>
import 'daterangepicker/daterangepicker';
import 'daterangepicker/daterangepicker.css';
import $ from 'jquery';
import moment from 'moment';

export default {
  name: 'y-date-picker',
  props: {
    value: {},
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    format: {
      type: String,
      default: 'DD/MM/YYYY',
    },
    btnLabel: {
      type: String,
      default: 'btnLabel',
    },
    btnClass: {
      type: String,
      default: 'y-button-outline',
    },
    iconClass: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'input', // input | icon | button
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    range: [],
    date: '',
  }),
  computed: {
    isSingleDatePicker() {
      return this.options.singleDatePicker;
    },
    startDate() {
      if (this.isSingleDatePicker) {
        return this.range;
      }
      return this.range[0];
    },
    endDate() {
      if (this.isSingleDatePicker) {
        return this.range;
      }
      return this.range[1];
    },
    customOptions() {
      return {
        opens: 'center',
        locale: {
          format: this.format,
          separator: ' - ',
          applyLabel: 'Ok',
          cancelLabel: 'Cancelar',
          fromLabel: 'De',
          toLabel: 'Até',
          customRangeLabel: 'Custom',
          weekLabel: 'S',
          daysOfWeek: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
          monthNames: [
            'Janeiro',
            'Favereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
          ],
          firstDay: 1,
        },
        ...this.options,
      };
    },
  },
  watch: {
    value(newValue) {
      const el = $('#y-calendar-input');
      if (this.isSingleDatePicker) {
        // Verifica se a nova data é válida antes de definir
        if (newValue) {
          el.data('daterangepicker').setStartDate(newValue);
        } else {
          el.data('daterangepicker').setStartDate(null);
        }
      } else if (newValue && newValue.length === 2) {
        el.data('daterangepicker').setStartDate(newValue[0]);
        el.data('daterangepicker').setEndDate(newValue[1]);
      } else {
        el.data('daterangepicker').setStartDate(null);
        el.data('daterangepicker').setEndDate(null);
      }
    },
    range(newValue) {
      // Emite o valor ou null se o valor for vazio ou inválido
      const valueToEmit = newValue && newValue.length > 0 ? newValue : null;
      this.$emit('change', valueToEmit);
      this.$emit('input', valueToEmit);
    },
  },
  methods: {
    setDateToToday() {
      const today = moment().format(this.format);
      this.range = today;
      this.date = today;
      $('#y-calendar-input').val(today);
    },
    show() {
      $('#y-calendar-input').data('daterangepicker').show();
    },
    hide() {
      $('#y-calendar-input').data('daterangepicker').hide();
    },
  },
  created() {
    this.range = this.value;
  },
  mounted() {
    this.$nextTick(() => {
      const el = $('#y-calendar-input');
      el.daterangepicker(
        {
          ...this.customOptions,
          autoUpdateInput: false,
          locale: {
            format: this.format,
            separator: ' - ',
            applyLabel: 'Ok',
            cancelLabel: 'Cancelar',
            fromLabel: 'De',
            toLabel: 'Até',
            customRangeLabel: 'Custom',
            weekLabel: 'S',
            daysOfWeek: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
            monthNames: [
              'Janeiro',
              'Favereiro',
              'Março',
              'Abril',
              'Maio',
              'Junho',
              'Julho',
              'Agosto',
              'Setembro',
              'Outubro',
              'Novembro',
              'Dezembro',
            ],
            firstDay: 1,
          },
        },
        (start, end, label) => {
          // Adiciona logs para verificar valores
          console.log('Start Date:', start.format(this.format));
          console.log('End Date:', end.format(this.format));
        },
      );

      el.on('apply.daterangepicker', (event, picker) => {
        const startDate = picker.startDate.format(this.format);
        const endDate = picker.endDate.format(this.format);

        if (this.isSingleDatePicker) {
          this.range = startDate;
          this.date = startDate;
        } else {
          this.range = [startDate, endDate];
          this.date = `${startDate} - ${endDate}`;
        }

        el.val(this.date);
        el.attr('placeholder', '');
      });

      el.on('cancel.daterangepicker', () => {
        if (this.isSingleDatePicker) {
          this.range = '';
        } else {
          this.range = [];
        }
        el.val('');
        el.attr('placeholder', this.$t('generic-str.never'));
      });
    });
  },
  beforeDestroy() {
    $('#y-calendar-input').daterangepicker('hide').daterangepicker('destroy');
  },
};
</script>

<style scoped>
.y-calendar-container {
  position: relative;
}
.y-calendar-container input {
  position: absolute;
}
.hide-opacity {
  opacity: 0;
}
.icon-calendar {
  font-size: 30px;
}
.y-button-outline {
  padding: 6px 24px;
  border-radius: 8px;
  background: #6b23dc;
  color: #fff;
  border: none;
  margin-top: 15px;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  margin-left: 22px;
}

.y-button-outline {
  background: #fff;
  color: #6b23dc;
  border: 1px solid #6b23dc;
}

.y-button-outline:hover {
  background: #732be6;
}

.y-button-outline:hover {
  background: #e4d8f7;
}
button {
  z-index: 1;
  position: relative;
}
.button-container input {
  left: 30px;
}
</style>
